import axios from "axios";
import AppConfig from "../config";
import { hasValue } from "../utils/commonFns";

const baseURL = `${AppConfig.API_URL}/PublicVendor`;

const getPublicVendors = async (nameFilter) => {
  try {
    var url = `${baseURL}?`;
    if (hasValue(nameFilter)) url += `name=${nameFilter}&`;
    let { data } = await axios.get(`${url}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getPublicVendorById = async (id) => {
  try {
    let { data } = await axios.get(`${baseURL}/${id}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const addPublicVendors = async (PublicVendor) => {
  try {
    //let { data } = await axios.post(`${baseURL}`, JSON.stringify(PublicVendor));

    let { data } = await axios.post(`${baseURL}`, PublicVendor, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const UpdatePublicVendors = async (PublicVendor) => {
  try {
    // let { data } = await axios.put(`${baseURL}`, JSON.stringify(PublicVendor));
    let { data } = await axios.put(`${baseURL}`, PublicVendor, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const DeletePublicVendors = async (id) => {
  try {
    let { data } = await axios.delete(`${baseURL}/${id}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  getPublicVendors,
  getPublicVendorById,
  addPublicVendors,
  UpdatePublicVendors,
  DeletePublicVendors,
};
