import React from "react";
import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
import "./assets/base.css";
import "./assets/template-override.css";
import "react-toastify/dist/ReactToastify.css";
import Main from "./pages/Main";
import { AuthProvider } from "./context/login";
import { ThemeProvider } from "./context/theme";
import "./utils/interceptor";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import ErrorBoundary from "./utils/ErrorBoundary";
import { createRoot } from "react-dom/client";
import "@mdxeditor/editor/style.css";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

const renderApp = () => {
  root.render(
    <ErrorBoundary>
      <AuthProvider>
        <ThemeProvider>
          {/* <BrowserRouter basename={"/assessor"}> */}
          <Main />
          {/* </BrowserRouter> */}
        </ThemeProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

renderApp();

unregister();

registerServiceWorker();
