import React, { useReducer } from 'react';
import { initialState, themeReducer } from './reducer';

const ThemeStateContext = React.createContext();
const ThemeDispatchContext = React.createContext();

export function useThemeState() {
	const context = React.useContext(ThemeStateContext);
	if (context === undefined) {
		throw new Error('useThemeState must be used within a themeProvider');
	}

	return context;
}

export function useThemeDispatch() {
	const context = React.useContext(ThemeDispatchContext);
	if (context === undefined) {
		throw new Error('useThemeDispatch must be used within a ThemeProvider');
	}

	return context;
}

export const ThemeProvider = ({ children }) => {
	const [user, dispatch] = useReducer(themeReducer, initialState);

	return (
		<ThemeStateContext.Provider value={user}>
			<ThemeDispatchContext.Provider value={dispatch}>
				{children}
			</ThemeDispatchContext.Provider>
		</ThemeStateContext.Provider>
	);
};