import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import { useAuthState } from "../../context/login";

import { ToastContainer } from "react-toastify";
import { Roles } from "../../utils/constants";
import RiderMatrix from "../../pages/RiderMatrix";
import VendorQuestionnaire from "../../pages/VendorQuestionnaire";
import Remediations from "../../pages/Remediations";
import RemediationDetails from "../../pages/Remediations/RemediationDetails";
import RolesAndPermissionDetails from "../../pages/Roles/RolesAndPermissionDetails";
import TPRMSupportServiceDashboard from "../../pages/TPRMSupportService";
import PublicVendors from "../../pages/public-vendors";

const BusinessDomain = lazy(() => import("../../pages/BusinessDomains"));
const ThirdPartyAssessmentRequestDetailView = lazy(() =>
  import(
    "../../pages/ThirdPartyAssessmentRequest/thirdPartyAssessmentRequestDetailView"
  )
);
const ThirdPartyAssessmentRequestList = lazy(() =>
  import("../../pages/ThirdPartyAssessmentRequest")
);
const SessionIdle = lazy(() => import("../../pages/session"));
const VendorExchange = lazy(() => import("../../pages/vendor-exchange"));
const VendorAssessments = lazy(() => import("../../pages/vendor-assessments"));
const Assessors = lazy(() => import("../../pages/Assessors"));
const VendorDetails = lazy(() => import("../../pages/vendor-details"));
const Questionnaire = lazy(() => import("../../pages/questionnaire"));
const Login = lazy(() => import("../../pages/Login"));
const VendorAssessmentsList = lazy(() =>
  import("../../pages/vendor-assessments/assessmentsList")
);
const BusinessOrg = lazy(() => import("../../pages/BusinessOrg"));
const ClientSupport = lazy(() => import("../../pages/ClientSupport"));

const ThirdPartyAssessmentRequestListPending = () => {
  return <ThirdPartyAssessmentRequestList status="SubmittedToLead" />;
};

const AppMain = () => {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/admin",
        element: (
          <ProtectedComponent isAdminRoute>
            <Assessors />
          </ProtectedComponent>
        ),
      },
      {
        path: "/business-org",
        element: (
          <ProtectedComponent isAdminRoute>
            <BusinessOrg />
          </ProtectedComponent>
        ),
      },
      {
        path: "/business-domains",
        element: (
          <ProtectedComponent isAdminRoute>
            <BusinessDomain />
          </ProtectedComponent>
        ),
      },
      {
        path: "/rider-details",
        element: (
          <ProtectedComponent isAdminRoute>
            <RiderMatrix />
          </ProtectedComponent>
        ),
      },
      {
        path: "/vendor-questionnaire",
        element: (
          <ProtectedComponent isAdminRoute>
            <VendorQuestionnaire />
          </ProtectedComponent>
        ),
      },
      {
        path: "/tprm-support-service-requests",
        element: (
          <ProtectedComponent>
            <TPRMSupportServiceDashboard />
          </ProtectedComponent>
        ),
      },
      {
        path: "/third-party-requests",
        element: (
          <ProtectedComponent>
            <ThirdPartyAssessmentRequestList />
          </ProtectedComponent>
        ),
      },
      {
        path: "/third-party-request-deails/:id?",
        element: (
          <ProtectedComponent>
            <ThirdPartyAssessmentRequestDetailView />
          </ProtectedComponent>
        ),
      },
      {
        path: "/third-party-pending",
        element: (
          <ProtectedComponent>
            <ThirdPartyAssessmentRequestListPending />
          </ProtectedComponent>
        ),
      },
      {
        path: "/information-hub",
        element: (
          <ProtectedComponent>
            <VendorExchange />
          </ProtectedComponent>
        ),
      },
      {
        path: "/public-vendors",
        element: (
          <ProtectedComponent>
            <PublicVendors />
          </ProtectedComponent>
        ),
      },
      {
        path: "/vendor/assessments",
        element: (
          <ProtectedComponent>
            <VendorAssessments />
          </ProtectedComponent>
        ),
      },
      {
        path: "/vendor/vendor-details/:id?",
        element: (
          <ProtectedComponent>
            <VendorDetails />
          </ProtectedComponent>
        ),
      },
      {
        path: "/vendor/questionnaire/:id",
        element: (
          <ProtectedComponent>
            <Questionnaire />
          </ProtectedComponent>
        ),
      },
      {
        path: "/vendor/assessment/:id",
        element: (
          <ProtectedComponent>
            <VendorAssessmentsList />
          </ProtectedComponent>
        ),
      },
      {
        path: "/remediations",
        element: (
          <ProtectedComponent>
            <Remediations />
          </ProtectedComponent>
        ),
      },
      {
        path: "/remediation/:id",
        element: (
          <ProtectedComponent>
            <RemediationDetails />
          </ProtectedComponent>
        ),
      },
      {
        path: "/client-support",
        element: (
          <ProtectedComponent>
            <ClientSupport />
          </ProtectedComponent>
        ),
      },
      {
        path: "/roles-permissions",
        element: (
          <ProtectedComponent>
            <RolesAndPermissionDetails />
          </ProtectedComponent>
        ),
      },
      {
        path: "*",
        element: <RedirectorAuth />,
      },
    ],
    {
      basename: "/assessor",
    }
  );

  return (
    <Fragment>
      <Suspense fallback={<div id="loader"></div>}>
        <RouterProvider router={router} />
        <SessionIdle />
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div id="loader"></div>
    </Fragment>
  );
};

const RedirectorAuth = () => {
  const userDetails = useAuthState();
  const isAdmin =
    userDetails?.user?.roleDesc === Roles.ADMIN && Boolean(userDetails.token);
  return isAdmin ? (
    <Navigate replace to="/admin" />
  ) : (
    <Navigate replace to="/information-hub" />
  );
};

const ProtectedComponent = ({ children, isAdminRoute }) => {
  const userDetails = useAuthState();
  const validRoute = isAdminRoute
    ? userDetails?.user?.roleDesc === Roles.ADMIN && Boolean(userDetails.token)
    : Boolean(userDetails.token);
  return !validRoute ? <Navigate replace to="/" /> : <>{children}</>;
};

export default AppMain;
