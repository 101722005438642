import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import AppContent from "../AppContent";
import { DataGrid } from "@mui/x-data-grid";
import {
  Card,
  CardBody,
  InputGroup,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Row,
  Col,
  Input,
  InputGroupText,
} from "reactstrap";
import { deleteRider, getRider } from "../../services/riderMatrixService";
import { debounce, hasValue } from "../../utils/commonFns";
import { faRotate, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddRiderMatrix from "./AddUpdateRider";
import ConfirmationModal from "../Common/ConfirmationModal";

export default function RiderMatrix() {
  const [isNewRiderMatrixModalOpen, setRiderMatrixModalOpen] = useState(false);
  const [selectedRiderMatrixId, SetRiderMatrixId] = useState(null);

  const [isNewConfirmationModalOpen, setisNewConfirmationModalOpen] =
    useState(false);
  const [deleteText, SetDeleteText] = useState(null);

  const colDefs = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return (
          <a
            style={{
              textDecoration: "underline !important",
              color: "#545cd8",
              cursor: "pointer",
            }}
            onClick={() => {
              setRiderMatrixModalOpen(true);
              SetRiderMatrixId(params.row.id);
            }}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      field: "docName",
      headerName: "Document",
      width: 250,
      renderCell: (params) => {
        return (
          <a href={params.row.docSignedUrl} target="_">
            {params.row.docName}
          </a>
        );
      },
    },
    {
      field: "id",
      headerName: "Delete",
      width: 200,
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            title="Delete"
            icon={faTrash}
            onClick={() => {
              SetDeleteText(`Do you want to delete "${params.row.name}"?`);
              setisNewConfirmationModalOpen(true);
              SetRiderMatrixId(params.value);
            }}
            style={{ paddingLeft: "15px" }}
          />
        );
      },
    },
  ];

  const [riderData, setRiderData] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  const setIsNewRiderMatrixModalOpen = (show, success) => {
    setRiderMatrixModalOpen(show);
    if (success) fetchData();
  };

  const setConfirmationModalOpen = (show, success) => {
    setisNewConfirmationModalOpen(show);
    if (success) {
      deleteData(selectedRiderMatrixId);
    }
  };

  const deleteData = async (id) => {
    const data = await deleteRider(id);
    if (hasValue(data)) {
      fetchData();
    }
  };

  const fetchData = async () => {
    const data = await getRider(nameFilter);
    if (hasValue(data)) {
      setRiderData(data);
    } else {
      setRiderData([]);
    }
  };

  useEffect(() => {
    processDebounce();
  }, [nameFilter]);

  const processDebounce = debounce(() => fetchData(), 1000);

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle
                heading="Rider"
                BreadcrumbComp={
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Rider Details</BreadcrumbItem>
                  </Breadcrumb>
                }
                PageTitleActions={
                  <>
                    <Button
                      color="primary"
                      onClick={() => {
                        SetRiderMatrixId(null);
                        setRiderMatrixModalOpen(true);
                      }}
                    >
                      + New Rider
                    </Button>
                  </>
                }
              />
              <Card className="main-card mb-3">
                <CardBody>
                  <Row style={{ marginTop: 8, marginBottom: 16 }}>
                    <Col sm={2}>
                      <InputGroup>
                        <Input
                          type="text"
                          name="name"
                          autocomplete="off"
                          value={nameFilter}
                          onChange={(e) => {
                            setNameFilter(e.target.value);
                          }}
                          id="name"
                          placeholder="Search with Name"
                        />

                        <InputGroupText>
                          <FontAwesomeIcon
                            title="Refresh"
                            icon={faRotate}
                            onClick={() => {
                              fetchData();
                            }}
                          />
                        </InputGroupText>
                      </InputGroup>
                    </Col>
                    <Col></Col>
                  </Row>
                  <div className="business-org-grid-container">
                    <DataGrid
                      rows={riderData}
                      columns={colDefs}
                      pageSize={20}
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      density="compact"
                    />
                  </div>
                </CardBody>
              </Card>
              {isNewRiderMatrixModalOpen && (
                <AddRiderMatrix
                  isNewRiderMatrixModalOpen={isNewRiderMatrixModalOpen}
                  setIsNewRiderMatrixModalOpen={setIsNewRiderMatrixModalOpen}
                  riderMatrixId={selectedRiderMatrixId}
                />
              )}
              {isNewConfirmationModalOpen && (
                <ConfirmationModal
                  isNewConfirmationModalOpen={isNewConfirmationModalOpen}
                  setConfirmationModalOpen={setConfirmationModalOpen}
                  bodyText={deleteText}
                />
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
