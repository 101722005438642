import axios from "axios";
import AppConfig from "../config";

const baseURL = `${AppConfig.API_URL}/Users`;

const addUser = async (assesor) => {
  try {
    let { data } = await axios.post(`${baseURL}`, JSON.stringify(assesor));
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const updateUser = async (assesor) => {
  try {
    let { data } = await axios.put(`${baseURL}`, JSON.stringify(assesor));
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const DeleteUser = async (id) => {
  try {
    let { data } = await axios.delete(`${baseURL}/${id}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getUsers = async () => {
  try {
    let { data } = await axios.get(`${baseURL}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getUsersById = async (id) => {
  try {
    let { data } = await axios.get(`${baseURL}/${id}`);

    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getUsersByRole = async (roles) => {
  try {
    let { data } = await axios.post(`${baseURL}/get-user-by-role`, roles);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const impersonateLogin = async (request) => {
  try {
    let { data } = await axios.post(`${baseURL}/impersonate`, request);
    if (data) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  getUsers,
  addUser,
  getUsersByRole,
  updateUser,
  DeleteUser,
  getUsersById,
  impersonateLogin,
};
