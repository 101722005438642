"use client";

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
// import { useNavigate } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { hasValue } from "../../../utils/commonFns";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}`}</Typography>
      </Box>
    </Box>
  );
}

export function AssessorDetailedList({ sx }) {
  // let navigate = useNavigate();

  const [selectedAssessor, setSelectedAssessor] = React.useState("");
  const handleChange = (event) => {
    setSelectedAssessor(event.target.value);
  };

  const assessments = [
    {
      client: "Artemis",
      assigned: 10,
      inprogress: 8,
      pending: 6,
      todo: 2,
      completed: 5,
    },
    {
      client: "XYZ Corp",
      assigned: 4,
      inprogress: 2,
      pending: 0,
      todo: 0,
      completed: 2,
    },
    {
      client: "Amazon",
      assigned: 20,
      inprogress: 15,
      pending: 6,
      todo: 2,
      completed: 5,
    },
    {
      client: "Microsoft",
      assigned: 1,
      inprogress: 0,
      pending: 0,
      todo: 1,
      completed: 0,
    },
  ];

  return (
    <Card sx={sx} className="tprm-donut-graph">
      <CardHeader
        title="Assessor Detailed Overview"
        action={
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Assessor</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedAssessor}
              label="Assessor"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Nagaraj K</MenuItem>
              <MenuItem value={20}>Himanshu J</MenuItem>
              <MenuItem value={30}>Haripal S</MenuItem>
              <MenuItem value={30}>Sandeep S</MenuItem>
              <MenuItem value={30}>Unassigned</MenuItem>
            </Select>
          </FormControl>
        }
      />
      <Divider />
      {hasValue(selectedAssessor) ? (
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer sx={{ maxHeight: 620 }}>
            <Table sx={{ minWidth: 800 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Assigned #</TableCell>
                  <TableCell>Inprogress</TableCell>
                  <TableCell>Pending</TableCell>
                  <TableCell>To Do</TableCell>
                  <TableCell>Completed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments.map((order) => {
                  const colors = [
                    "primary",
                    "secondary",
                    "error",
                    "info",
                    "success",
                    "warning",
                  ];
                  const color =
                    colors[Math.floor(Math.random() * colors.length)];

                  return (
                    <TableRow hover key={order.client}>
                      <TableCell>{order.client}</TableCell>
                      <TableCell>
                        <LinearProgressWithLabel
                          value={order.assigned}
                          color={color}
                        />
                      </TableCell>
                      <TableCell>{order.inprogress}</TableCell>
                      <TableCell>{order.pending}</TableCell>
                      <TableCell>{order.todo}</TableCell>
                      <TableCell>{order.completed}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <h5>{"Please select a Assessor"}</h5>
      )}
    </Card>
  );
}
