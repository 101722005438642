import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import AppContent from "../AppContent";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardBody, Breadcrumb, BreadcrumbItem } from "reactstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getRemediations } from "../../services/remediationService";

export default function Remediations() {
  const colDefs = [
    {
      field: "clientSubmissionNo",
      headerName: "Assessment No",
      width: 250,
      renderCell: (params) => {
        return (
          <a
            style={{
              textDecoration: "underline !important",
              color: "#545cd8",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/remediation/" + params.row.remediationID);
            }}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      field: "businessOrgName",
      headerName: "Client",
      width: 200,
    },
    {
      field: "vendorName",
      headerName: "Third Party",
      width: 200,
    },
    {
      field: "assessorName",
      headerName: "Assessor Name",
      width: 200,
    },
    {
      field: "initiatedOn",
      headerName: "Initiated On",
      width: 200,
      renderCell: (params) => {
        return <>{moment(params.value).format("MMM DD, YYYY")}</>;
      },
    },
    {
      field: "",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {`${
              params.row.gaps?.filter((x) =>
                ["ClientReview", "Closed"].includes(x.remediationGapStatus)
              ).length
            } out of ${params.row.gaps?.length} Gaps are Submitted to client`}
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    loadRemediations();
  }, []);

  const [remediations, setRemediations] = useState([]);

  const loadRemediations = async () => {
    const { result } = await getRemediations();
    setRemediations(result);
  };

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle
                heading="Remediations"
                BreadcrumbComp={
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Remediations</BreadcrumbItem>
                  </Breadcrumb>
                }
                PageTitleActions={<></>}
              />
              <Card className="main-card mb-3">
                <CardBody>
                  <div className="business-org-grid-container">
                    <DataGrid
                      rows={remediations}
                      columns={colDefs}
                      pageSize={20}
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      density="compact"
                      getRowId={(row) => row.remediationID}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
