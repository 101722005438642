import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomPasswordInput from '../../utils/CustomPassword';
import { checkPasswordValidations, hasValue } from '../../utils/commonFns';
import { toast } from 'react-toastify';
import { resetPassword, useAuthDispatch, useAuthState } from '../../context/login';

const PasswordResetModal = (props) => {

    const { isPasswordResetModalOpen, setIsPasswordResetModalOpen } = props;
    const dispatch = useAuthDispatch();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { user } = useAuthState();

    useEffect(() => {
        if (isPasswordResetModalOpen) {
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
        }
    }, [isPasswordResetModalOpen]);

    const resetPasswordBtnClick = async () => {

        if (!hasValue(currentPassword)) {
            toast("Please Enter Old Password", {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        if (!checkPasswordValidations(newPassword, confirmPassword)) return;

        if (currentPassword === newPassword) {
            toast("Old Password and New Password cannot be same", {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        let response = await resetPassword(dispatch, { userName: user.userName, currentPassword, newPassword });
        if (!response?.id) return;
        toast("Password Reset Successful", {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_CENTER
        });
        setIsPasswordResetModalOpen(false);
    };

    const handleModalClose = () => {
        if (!user.isUserResetPassword) {
            toast("You must reset your password before continuing", {
                type: toast.TYPE.ERROR,
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }
        setIsPasswordResetModalOpen(false);
    }

    return (
        <>
            <Modal size="md" isOpen={isPasswordResetModalOpen} toggle={handleModalClose}>
                <ModalHeader toggle={handleModalClose}>{"Reset Password"}</ModalHeader>
                <ModalBody>
                    <Card className="main-card">
                        <CardBody>
                            <div className='vendor-details-card'>
                                <Form inline>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup row>
                                                <Label required for="current-password" className="right-align required" sm={4}>Old Password</Label>
                                                <Col sm={8}>
                                                    <CustomPasswordInput name="current-password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} id="current-password" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup row>
                                                <Label required for="new-password" className="right-align required" sm={4}>New Password</Label>
                                                <Col sm={8}>
                                                    <CustomPasswordInput name="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} id="new-password" required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup row>
                                                <Label required for="confirm-password" className="right-align required" sm={4}>Confirm Password</Label>
                                                <Col sm={8}>
                                                    <CustomPasswordInput name="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} id="confirm-password" required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={resetPasswordBtnClick}>Reset</Button>
                    <Button onClick={handleModalClose}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default PasswordResetModal;