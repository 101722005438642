import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { hasValue } from "../../utils/commonFns";
import { getEvidence, uploadEvidence } from "../../services/remediationService";

const RemediationResponseModal = ({
  remediationGapEvidenceId,
  remediationResponseModalHandle,
}) => {
  const [evidenceDocument, setEvidenceDocument] = useState([
    {
      fileName: "",
      fileSignedURL: "",
      fileslist: null,
    },
  ]);

  const [remarks, setRemarks] = useState("");

  const uploadFileChange = (e) => {
    if (!hasValue(e) || !hasValue(e.target) || !hasValue(e.target.files))
      return;
    var fileslist = e.target.files;
    if (fileslist.length <= 0) return;
    const file = fileslist[0];

    if (file.size <= 0) {
      toast("Please upload valid file", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      toast("Please select file size less than 5 MB", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      e.target.value = "";
      return;
    }

    setEvidenceDocument({
      fileName: file.name,
      fileSignedURL: "",
      fileslist: file,
    });

    e.target.value = "";
  };

  useEffect(() => {
    if (remediationGapEvidenceId) {
      setEvidenceDocument({
        fileName: "",
        fileSignedURL: "",
        fileslist: null,
      });
      setRemarks("");
      loadEvidence();
    }
  }, [remediationGapEvidenceId]);

  const loadEvidence = async () => {
    const { result } = await getEvidence(remediationGapEvidenceId);
    if (result) {
      if (result?.evidenceFileName && result?.evidenceFileSignedUrl) {
        setEvidenceDocument({
          fileName: result?.evidenceFileName || "",
          fileSignedURL: result?.evidenceFileSignedUrl,
        });
      }
      setRemarks(result.remarks);
    }
  };

  const handleSave = async () => {
    if (!hasValue(evidenceDocument.fileName) && !hasValue(remarks)) {
      toast("Either Evidence or Remarks are mandatory", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const request = new FormData();
    request.append("RemediationGapId", remediationGapEvidenceId);
    if (hasValue(evidenceDocument.fileslist))
      request.append("EvidenceFile", evidenceDocument.fileslist);
    request.append("Remarks", remarks);

    const { result } = await uploadEvidence(request);

    if (result) {
      toast("Response updated successfully", {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      remediationResponseModalHandle(true);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={remediationGapEvidenceId}
        toggle={() => remediationResponseModalHandle(false)}
      >
        <ModalHeader toggle={() => remediationResponseModalHandle(false)}>
          {"Response"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm={12}>
                <Label
                  required
                  for="evidenceDocument"
                  className="left-align required col-form-label"
                >
                  Evidence
                </Label>
                <Col>
                  <InputGroup>
                    <div className="input-box">
                      {!hasValue(evidenceDocument.fileName) ? (
                        <Label className="placeholder">
                          Please Select Evidence Report
                        </Label>
                      ) : !hasValue(evidenceDocument.fileSignedURL) ? (
                        <Label>{evidenceDocument.fileName}</Label>
                      ) : (
                        <a href={evidenceDocument?.fileSignedURL} target="_">
                          {evidenceDocument.fileName}
                        </a>
                      )}
                    </div>
                    <InputGroupText style={{ cursor: "pointer" }}>
                      <input
                        name="evidenceDocument"
                        id="evidenceDocument"
                        style={{ display: "none" }}
                        type="file"
                        accept=".doc,.docx,.xls,.xlsx,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                    text/plain, application/pdf, image/*"
                        onChange={uploadFileChange}
                      />
                      <Label for="evidenceDocument">
                        <FontAwesomeIcon
                          title="fileSelect"
                          icon={faFile}
                          cursor="pointer"
                        />
                      </Label>
                    </InputGroupText>
                  </InputGroup>
                </Col>
              </Col>
              <Col sm={12}>
                <Label
                  required
                  for="remarks"
                  className="left-align required col-form-label"
                >
                  Remarks
                </Label>
                <Input
                  type="textarea"
                  value={remarks}
                  rows={5}
                  onChange={(evt) => setRemarks(evt.target.value)}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={() => remediationResponseModalHandle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RemediationResponseModal;
