import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { hasValue } from "../../utils/commonFns";
import { getQuery, postNewQuery } from "../../services/remediationService";
import ChatMsg from "./ChatMsg";
import { Box, TextField, IconButton, Avatar, Badge } from "@mui/material";
import { useDropzone } from "react-dropzone";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

const Chat = ({ chatRemediationGap, setChatRemediationGap }) => {
  const [msgs, setMsgs] = useState([]);
  const [newMsg, setNewMsg] = useState("");

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const handleRemoveFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleSend = async () => {
    if (!hasValue(newMsg)) return;

    const request = new FormData();
    request.append("RemediationGapID", chatRemediationGap.remediationGapId);
    request.append("Message", newMsg.trim());
    if (hasValue(files))
      files.forEach((file) => {
        request.append("Attachments", file);
      });

    const { result } = await postNewQuery(request);
    if (result) {
      setNewMsg("");
      setFiles([]);
      getQueries();
    }
  };

  const getQueries = async () => {
    const { result } = await getQuery(chatRemediationGap.remediationGapId);
    if (result) {
      setMsgs(result);
    }
  };

  useEffect(() => {
    if (hasValue(chatRemediationGap?.remediationGapId)) getQueries();
  }, [chatRemediationGap?.remediationGapId]);

  return (
    <Drawer
      anchor={"right"}
      open={hasValue(chatRemediationGap)}
      onClose={() => setChatRemediationGap(null)}
    >
      <div
        style={{
          width: "350px",
          padding: "16px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          {msgs.map((msg, index) => (
            <ChatMsg key={index} msg={msg} />
          ))}
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
            position: "relative",
            marginTop: "auto",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              value={newMsg}
              onChange={(e) => setNewMsg(e.target.value)}
              placeholder="Type a message..."
              variant="outlined"
              size="small"
              multiline
              rows={3}
              fullWidth
              sx={{
                marginRight: "10px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                "& .MuiInputBase-root": {
                  padding: "0px",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                flexDirection: "column",
              }}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <IconButton component="span">
                  <AttachFileIcon />
                </IconButton>
              </div>
              <IconButton color="primary" onClick={handleSend}>
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
            {files.map((file, index) => (
              <Badge
                key={index}
                overlap="circular"
                badgeContent={
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFile(file)}
                    sx={{ backgroundColor: "rgba(0,0,0,0.6)", color: "white" }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
                sx={{ marginRight: "10px", marginBottom: "10px" }}
              >
                <Avatar
                  src={file.preview}
                  variant="square"
                  sx={{ width: 48, height: 48, borderRadius: "5px" }}
                />
              </Badge>
            ))}
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default Chat;
