import React, { Fragment, useState, useEffect } from 'react';

import {
    DropdownToggle, DropdownMenu,
    Nav, NavItem, NavLink,
     UncontrolledButtonDropdown
} from 'reactstrap';

import {
    faAngleDown
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PasswordResetModal from '../../../pages/reset-password';
import user1 from '../../../assets/utils/images/avatars/user.png';
import { logout, useAuthDispatch, useAuthState } from '../../../context/login';

const UserBox = (props) => {

    const dispatch = useAuthDispatch();
    const { user } = useAuthState();
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);

    const logOutme = () => {
        logout(dispatch);
    };

    useEffect(() => {
        if (!user.isUserResetPassword) {
            setIsPasswordResetModalOpen(true);
        }
    }, []);


    return (
        <Fragment>
            <div className="header-btn-lg pe-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left  me-2 header-user-info">
                            <div className="widget-heading">
                                {`${user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)} ${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`}
                            </div>
                            <div className="widget-subheading">
                                {user.roleDesc}
                            </div>
                        </div>
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <img width={28} className="rounded-circle" src={user1} alt="" />
                                    <FontAwesomeIcon className="opacity-8" icon={faAngleDown} />
                                </DropdownToggle>
                                <DropdownMenu end className="rm-pointers">
                                    <Nav vertical>
                                        <NavItem>
                                            <NavLink href="#" onClick={() => setIsPasswordResetModalOpen(true)}>
                                                Reset Password
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" onClick={logOutme}>
                                                Logout
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <PasswordResetModal {...{ isPasswordResetModalOpen, setIsPasswordResetModalOpen }} />


                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserBox;