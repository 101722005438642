import axios from "axios";
import AppConfig from "../config";

const baseURL = `${AppConfig.API_URL}/Questionnaire`;

const updateIdealQuestionnaire = async (request) => {
  try {
    let { data } = await axios.post(
      `${baseURL}/update-ideal-response`,
      JSON.stringify(request)
    );
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const createUpdateQuestion = async (request) => {
  try {
    let { data } = await axios.post(
      `${baseURL}/create-update-question`,
      JSON.stringify(request)
    );
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteSection = async (request) => {
  try {
    let { data } = await axios.post(
      `${baseURL}/delete-section`,
      JSON.stringify(request)
    );
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const createUpdateSection = async (request) => {
  try {
    let { data } = await axios.post(
      `${baseURL}/create-update-section`,
      JSON.stringify(request)
    );
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteQuestion = async (request) => {
  try {
    let { data } = await axios.post(
      `${baseURL}/delete-question`,
      JSON.stringify(request)
    );
    if (data.success) {
      return data.success;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getQuestionnaireDetails = async (questionnaireId) => {
  try {
    let { data } = await axios.get(`${baseURL}/${questionnaireId}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  updateIdealQuestionnaire,
  getQuestionnaireDetails,
  createUpdateQuestion,
  createUpdateSection,
  deleteQuestion,
  deleteSection,
};
