import React from 'react';
import { sendErrorMail } from "../services/emailService";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {

        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        sendErrorMail(error.toString());
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<div>
                <h1>Something went wrong.</h1>
                <div>Error Details
                    <br />{this.state.error?.stack || this.state.error?.message}
                </div>
            </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;