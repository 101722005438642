import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { hasValue } from "../../utils/commonFns";
import { updateRemediationGapStatus } from "../../services/remediationService";
import { EnumRemediationGapStatus } from "../../utils/constants";

const QueryRemarksPopup = ({
  remediationGapHoldId,
  setRemediationGapHoldId,
  loadRemediationGaps,
}) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (remediationGapHoldId) {
      setQuery("");
    }
  }, [remediationGapHoldId]);

  const handleSave = async () => {
    if (!hasValue(query)) {
      toast("Please enter Remarks", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const { result } = await updateRemediationGapStatus({
      RemediationGapIds: [remediationGapHoldId],
      RemediationGapStatus: EnumRemediationGapStatus.OnHold,
      OnHoldRemarks: query,
    });

    if (result) {
      toast("Gap updated successfully", {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      setRemediationGapHoldId(null);
      loadRemediationGaps();
    }
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={hasValue(remediationGapHoldId)}
        toggle={() => setRemediationGapHoldId(null)}
      >
        <ModalHeader toggle={() => setRemediationGapHoldId(null)}>
          {"Remarks"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm={12}>
                <Label
                  required
                  for="remarks"
                  className="left-align required col-form-label"
                >
                  Remarks
                </Label>
                <Input
                  type="textarea"
                  value={query}
                  rows={5}
                  onChange={(evt) => setQuery(evt.target.value)}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={() => setRemediationGapHoldId(null)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default QueryRemarksPopup;
