import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { hasValue } from "../../utils/commonFns";

const RemediationRemarksPopup = ({ isOpen, onSave }) => {
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    if (isOpen) {
      setRemarks("");
    }
  }, [isOpen]);

  const handleSave = async () => {
    if (!hasValue(remarks)) {
      toast("Please enter Remarks", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    onSave(remarks);
  };

  return (
    <>
      <Modal size="md" isOpen={isOpen} toggle={() => onSave(null)}>
        <ModalHeader toggle={() => onSave(null)}>{"Remarks"}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm={12}>
                <Label
                  required
                  for="remarks"
                  className="left-align required col-form-label"
                >
                  Remarks
                </Label>
                <Input
                  type="textarea"
                  value={remarks}
                  rows={5}
                  onChange={(evt) => setRemarks(evt.target.value)}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={() => onSave(null)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RemediationRemarksPopup;
