import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import AppContent from "../AppContent";
import { DataGrid } from "@mui/x-data-grid";
import {
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import {
  deleteQuestion,
  deleteSection,
  getQuestionnaireDetails,
} from "../../services/vendorQuestionnaire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { hasValue } from "../../utils/commonFns";
import EditQuestionPopup from "./editQuestionPopup";
import { toast } from "react-toastify";
import EditSectionPopup from "./editSectionPopup";
import ConfirmationPopup from "./confirmationPopup";

export default function VendorQuestionnaire() {
  const colDefs = [
    {
      field: "sectionName",
      headerName: "Section Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <a
            style={{
              textDecoration: "underline !important",
              color: "#545cd8",
              cursor: "pointer",
              fontWeight:
                params.id === selectedSection?.id ? "bolder" : "normal",
            }}
            onClick={() => {
              setSelectedSection(params.row);
            }}
          >
            {" "}
            {params.value}
          </a>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            title="Edit"
            style={{
              cursor: "pointer",
              fontSize: 16,
            }}
            icon={faEdit}
            onClick={(e) => {
              setEditSection(params.row);
            }}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            title="Delete"
            icon={faTrash}
            onClick={() => {
              setDeleteItem(params.row);
            }}
            style={{ cursor: "pointer", fontSize: 16 }}
          />
        );
      },
    },
  ];

  const questionsColDefs = [
    {
      field: "question_name",
      headerName: "Question Name",
      flex: 1,
    },
    {
      field: "idealResponse",
      headerName: "Ideal Response",
      width: 200,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 75,
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            title="Edit"
            style={{
              cursor: "pointer",
              fontSize: 16,
            }}
            icon={faEdit}
            onClick={(e) => {
              setEditQuestion(params.row);
            }}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 75,
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            title="Delete"
            icon={faTrash}
            onClick={() => {
              setDeleteItem(params.row);
            }}
            style={{ cursor: "pointer", fontSize: 16 }}
          />
        );
      },
    },
  ];

  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  const [editQuestion, setEditQuestion] = useState(null);
  const [editSection, setEditSection] = useState(null);

  const [deleteItem, setDeleteItem] = useState(null);

  const loadQuestionnaireData = async () => {
    const response = await getQuestionnaireDetails(1);
    setSections(response);
  };

  const handleAddQuestion = () => {
    if (!hasValue(selectedSection?.id)) {
      toast("Please Select Section", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setEditQuestion({});
  };

  const handleConfirmationPopup = async (handleContinue) => {
    if (handleContinue) {
      if (hasValue(deleteItem.question_name)) {
        const result = await deleteQuestion({
          SectionId: selectedSection?.id,
          QuestionId: deleteItem?.id,
        });
        if (result) {
          setDeleteItem(null);
          toast("Question Deleted Successfully", {
            type: "success",
            position: toast.POSITION.TOP_CENTER,
          });
          loadQuestionnaireData();
        }
      } else {
        const result = await deleteSection({
          SectionId: deleteItem?.id,
        });
        if (result) {
          setDeleteItem(null);
          setSelectedSection(null);
          toast("Section Deleted Successfully", {
            type: "success",
            position: toast.POSITION.TOP_CENTER,
          });
          loadQuestionnaireData();
        }
      }
    } else {
      setDeleteItem(null);
    }
  };

  const handleAddSection = () => {
    setEditSection({});
  };

  useEffect(() => {
    loadQuestionnaireData();
  }, []);

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle
                heading="Vendor Questionnaire"
                BreadcrumbComp={
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Vendor Questionnaire</BreadcrumbItem>
                  </Breadcrumb>
                }
              />
              <Card className="main-card mb-3">
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <div
                        className="mb-3"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button color="primary" onClick={handleAddSection}>
                          + Add Section
                        </Button>
                      </div>
                      <div style={{ height: "calc(100vh - 230px)" }}>
                        <DataGrid
                          rows={sections}
                          columns={colDefs}
                          pageSize={20}
                          disableSelectionOnClick
                          disableRowSelectionOnClick
                          density="compact"
                        />
                      </div>
                    </Col>
                    <Col md={9}>
                      <div
                        className="mb-3"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button color="primary" onClick={handleAddQuestion}>
                          + Add Question
                        </Button>
                      </div>
                      <div style={{ height: "calc(100vh - 230px)" }}>
                        <DataGrid
                          rows={
                            !hasValue(selectedSection?.id) ||
                            sections.length <= 0
                              ? []
                              : sections
                                  .filter((x) => x.id === selectedSection.id)[0]
                                  .questions.map((x) => ({
                                    id: x.id,
                                    question_name: x.question_name,
                                    idealResponse: x.idealResponse,
                                  }))
                          }
                          columns={questionsColDefs}
                          disableSelectionOnClick
                          disableRowSelectionOnClick
                          density="compact"
                          slots={{
                            noRowsOverlay: () => {
                              return (
                                <div className="custom-msg-overlay">
                                  <Label>
                                    {!hasValue(selectedSection?.id)
                                      ? "Please Click on Section to show Questions"
                                      : "No Rows"}
                                  </Label>
                                </div>
                              );
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {hasValue(editQuestion) && (
                    <EditQuestionPopup
                      {...{
                        editQuestion,
                        setEditQuestion,
                        selectedSection,
                        loadQuestionnaireData,
                      }}
                    />
                  )}
                  {hasValue(editSection) && (
                    <EditSectionPopup
                      {...{
                        editSection,
                        setEditSection,
                        loadQuestionnaireData,
                      }}
                    />
                  )}
                  {hasValue(deleteItem) && (
                    <ConfirmationPopup
                      {...{
                        deleteItem,
                        handleConfirmationPopup,
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
