import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import AppContent from "../AppContent";
import { DataGrid } from "@mui/x-data-grid";
import {
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Button,
  Input,
  InputGroup,
  Row,
  Col,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  faMagnifyingGlass,
  faUserCheck,
  faGear,
  faArrowUpRightFromSquare,
  faInfoCircle,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, useNavigate } from "react-router-dom";
import {
  getGapsByRemediationID,
  getRemediation,
  linkAssessor,
  updateRemediationGapStatus,
  updateRemediationStatus,
} from "../../services/remediationService";
import { useAuthState } from "../../context/login";
import AssessorPopup from "../ThirdPartyAssessmentRequest/popups/assessorPopup";
import {
  EnumRemediationGapStatus,
  EnumRemediationStatus,
  Roles,
} from "../../utils/constants";
import RemediationResponseModal from "./RemediationResponseModal";
import QueryRemarksPopup from "./QueryRemarksPopup";
import { toast } from "react-toastify";
import RemediationRemarksPopup from "./RemediationRemarksPopup";
import { hasValue } from "../../utils/commonFns";
import Chat from "./Chat";

export default function RemediationDetails() {
  const colDefs = [
    {
      field: "domainName",
      headerName: "Domain",
      width: 150,
    },
    {
      field: "issueIdentified",
      headerName: "Issue Identified",
      width: 250,
    },
    {
      field: "issueSeverity",
      headerName: "Severity",
      width: 90,
    },
    {
      field: "risk",
      headerName: "Risk",
      width: 250,
    },
    {
      field: "remediation",
      headerName: "Remediation",
      width: 250,
    },
    {
      field: "evidence",
      headerName: "Evidence",
      width: 250,
    },
    {
      field: "displayStatus",
      headerName: "Status",
      width: 130,
    },
    {
      field: "",
      headerName: "Query",
      width: 100,
      renderCell: (params) => {
        if (params.row.hasQueries) {
          return (
            <FontAwesomeIcon
              title="Comments"
              className="cursor-pointer"
              icon={faCommentDots}
              fontSize={20}
              onClick={() => {
                setChatRemediationGap(params.row);
              }}
              style={{ paddingLeft: "25px" }}
            />
          );
        }
      },
    },
    {
      headerName: "On Hold Remarks",
      renderCell: (params) => {
        if (
          params.row.remediationGapStatus === EnumRemediationGapStatus.OnHold
        ) {
          return params.row.onHoldRemarks;
        }
      },
      width: 150,
    },
    {
      field: "id",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        if (
          params.row.remediationGapStatus !== EnumRemediationGapStatus.Closed
        ) {
          return (
            <>
              <ButtonGroup className="my-2">
                <UncontrolledDropdown className="remediation-details-action-dropdown">
                  <DropdownToggle color="outline">
                    <FontAwesomeIcon
                      fontSize={20}
                      icon={faGear}
                    ></FontAwesomeIcon>
                  </DropdownToggle>
                  <DropdownMenu>
                    {params.row.remediationGapStatus !==
                      EnumRemediationGapStatus.OnHold && (
                      <DropdownItem
                        onClick={() =>
                          setRemediationGapEvidenceId(
                            params.row.remediationGapId
                          )
                        }
                      >
                        View / Edit Response
                      </DropdownItem>
                    )}
                    {params.row.remediationGapStatus ===
                      EnumRemediationGapStatus.SubmittedToBeaconer && (
                      <>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={async () => {
                            const { result } = await updateRemediationGapStatus(
                              {
                                RemediationGapIds: [
                                  params.row.remediationGapId,
                                ],
                                RemediationGapStatus:
                                  EnumRemediationGapStatus.ClientReview,
                              }
                            );

                            if (result) {
                              toast("Gap updated successfully", {
                                type: toast.TYPE.SUCCESS,
                                position: toast.POSITION.TOP_CENTER,
                              });
                              loadRemediationGaps();
                            }
                          }}
                        >
                          Move to Client Review
                        </DropdownItem>
                      </>
                    )}
                    {![
                      EnumRemediationGapStatus.OnHold,
                      EnumRemediationGapStatus.Closed,
                      EnumRemediationGapStatus.ClientReview,
                    ].includes(params.row.remediationGapStatus) && (
                      <>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() =>
                            setRemediationGapHoldId(params.row.remediationGapId)
                          }
                        >
                          Mark as On Hold
                        </DropdownItem>
                      </>
                    )}
                    {params.row.remediationGapStatus ===
                      EnumRemediationGapStatus.OnHold && (
                      <>
                        <DropdownItem
                          onClick={() => resetHold(params.row.remediationGapId)}
                        >
                          Reset Hold
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const { user } = useAuthState();
  const isLeadAssessor = user?.roleDesc === Roles.LEADASSESSOR;

  const navigate = useNavigate();
  const queryParams = useParams();
  const remediationId = queryParams["id"];

  const [isAssessorPopupOpen, setIsAssessorPopupOpen] = useState(false);
  const [remediationGapEvidenceId, setRemediationGapEvidenceId] =
    useState(null);
  const [remediationGapHoldId, setRemediationGapHoldId] = useState(null);
  const [remediationGaps, setRemediationGaps] = useState([]);
  const [remediationData, setRemediationData] = useState({});
  const [isRemarksPopupOpen, setRemarksPopupOpen] = useState(null);
  const [chatRemediationGap, setChatRemediationGap] = useState(null);

  const loadRemediationGaps = async () => {
    const { result } = await getGapsByRemediationID(remediationId);
    setRemediationGaps(result);
  };
  const loadRemediation = async () => {
    const { result } = await getRemediation(remediationId);
    setRemediationData(result);
  };

  useEffect(() => {
    loadRemediationGaps();
    loadRemediation();
  }, []);

  const handleRemarksSave = (remarks) => {
    if (hasValue(remarks)) {
      moveRemediationStatus(remarks);
    }
    setRemarksPopupOpen(null);
  };

  const handleMoveToInprogress = () => {
    if (!hasValue(remediationData.assessorId)) {
      toast("Please assign Assessor", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (remediationData.isAlreadySentToVendor) {
      setRemarksPopupOpen(true);
    } else {
      moveRemediationStatus();
    }
  };

  const handleAsssignToClick = async () => {
    if (!isLeadAssessor) {
      const { result } = await linkAssessor({
        RemediationID: remediationId,
        AssessorId: user.id,
      });
      if (result) {
        loadRemediation();
      }
    } else {
      setIsAssessorPopupOpen(true);
    }
  };

  const handleAssessorAssignment = async (assessor) => {
    const { result } = await linkAssessor({
      RemediationID: remediationId,
      AssessorId: assessor.id,
    });
    if (result) {
      loadRemediation();
    }
  };

  const remediationResponseModalHandle = (isSave) => {
    setRemediationGapEvidenceId(null);
    if (isSave) {
      loadRemediationGaps();
    }
  };

  const moveRemediationStatus = async (vendorRemarks) => {
    const { result } = await updateRemediationStatus({
      RemediationID: remediationId,
      RemediationStatus: "WithVendor",
      RemarksForVendor: vendorRemarks,
    });
    if (result) {
      loadRemediation();
    }
  };

  const resetHold = async (remediationGapId) => {
    const { result } = await updateRemediationGapStatus({
      RemediationGapIds: [remediationGapId],
      RemediationGapStatus: EnumRemediationGapStatus.Initiated,
    });
    if (result) {
      toast("Gap updated successfully", {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      loadRemediationGaps();
    }
  };

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle
                heading="Remediation Gap Details"
                BreadcrumbComp={
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="/assessor/remediations">Remediations</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      Remediation Gap Details
                    </BreadcrumbItem>
                  </Breadcrumb>
                }
                PageTitleActions={<></>}
              />
              <Card className="remediation-card mb-3">
                <CardBody>
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                    <div style={{ marginLeft: "auto" }}>
                      {remediationData.remediationStatus ===
                      EnumRemediationStatus.WithVendor ? (
                        <Button
                          color="info"
                          className="ms-3 mb-2"
                          outline
                          disabled
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: "4px" }}
                            icon={faInfoCircle}
                          ></FontAwesomeIcon>
                          Sent to Vendor
                        </Button>
                      ) : (
                        <Button
                          color="info"
                          className="ms-3 mb-2"
                          onClick={handleMoveToInprogress}
                        >
                          Send to Vendor
                        </Button>
                      )}
                    </div>
                  </div>
                  <Row className="mt-2">
                    <Col md={6} className="mb-3">
                      <Label
                        required
                        for="assessor_name"
                        className="left-align col-form-label"
                      >
                        Assessor
                      </Label>
                      <Col>
                        <InputGroup>
                          <Input
                            name="assessor_name"
                            id="assessor_name"
                            value={remediationData.assessorName}
                            readOnly
                          />
                          <Button onClick={handleAsssignToClick}>
                            <FontAwesomeIcon
                              icon={
                                isLeadAssessor ? faMagnifyingGlass : faUserCheck
                              }
                            />
                          </Button>
                        </InputGroup>
                      </Col>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Label
                        required
                        for="vendorName"
                        className="left-align col-form-label"
                      >
                        Vendor Name
                      </Label>

                      <Col>
                        <InputGroup>
                          <Input
                            name="vendorName"
                            id="vendorName"
                            value={remediationData.vendorName}
                            readOnly
                          />
                          <Button
                            onClick={() =>
                              navigate(
                                `/vendor/vendor-details/${remediationData.vendorId}`
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          </Button>
                        </InputGroup>
                      </Col>
                    </Col>
                    {remediationData.remediationStatus ===
                      EnumRemediationStatus.WithVendor &&
                      hasValue(remediationData.remarksForVendor) && (
                        <>
                          <Col md={6} className="mb-3">
                            <Label
                              required
                              for="remarks"
                              className="left-align col-form-label"
                            >
                              Vendor Remarks
                            </Label>
                            <Col>
                              <Input
                                name="remarks"
                                id="remarks"
                                value={remediationData.remarksForVendor}
                                readOnly
                              />
                            </Col>
                          </Col>
                        </>
                      )}
                  </Row>

                  <div className="business-org-grid-container mt-2">
                    <DataGrid
                      rows={remediationGaps}
                      columns={colDefs}
                      pageSize={20}
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      density="compact"
                      getRowId={(row) => row.remediationGapId}
                      sx={{
                        "& .MuiDataGrid-cellContent": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          padding: "8px",
                        },
                      }}
                      getRowHeight={() => "auto"}
                    />
                  </div>
                </CardBody>
              </Card>
              {isAssessorPopupOpen && (
                <AssessorPopup
                  {...{
                    isAssessorPopupOpen,
                    setIsAssessorPopupOpen,
                    handleAssessorAssignment,
                  }}
                />
              )}
              {remediationGapEvidenceId && (
                <RemediationResponseModal
                  {...{
                    remediationGapEvidenceId,
                    remediationResponseModalHandle,
                  }}
                />
              )}
              {remediationGapHoldId && (
                <QueryRemarksPopup
                  {...{
                    remediationGapHoldId,
                    setRemediationGapHoldId,
                    loadRemediationGaps,
                  }}
                />
              )}
              <RemediationRemarksPopup
                isOpen={hasValue(isRemarksPopupOpen)}
                onSave={handleRemarksSave}
              />
              <Chat {...{ chatRemediationGap, setChatRemediationGap }} />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
