export const ThirdPartyAssessmentRequestsNav = [
  {
    icon: "pe-7s-diamond",
    label: "Third Party Assessments",
    to: "/assessor/third-party-requests",
    internalRoutes: ["/third-party-request-deails/"],
  },
];

export const LeadAssessorPendingNav = [
  {
    icon: "pe-7s-display1",
    label: "Pending Approvals",
    to: "/assessor/third-party-pending",
    internalRoutes: ["/third-party-request-deails/"],
    searchParams: "?from=pending",
  },
];

export const VendorExchageNav = [
  {
    icon: "pe-7s-rocket",
    label: "Information Hub",
    to: "/assessor/information-hub",
    internalRoutes: ["/assessor/vendor/vendor-details/"],
  },
];

export const PublicVendorNav = [
  {
    icon: "pe-7s-global",
    label: "Public Vendors",
    to: "/assessor/public-vendors",
  },
];
export const VendorAssessmentsNav = [
  {
    icon: "pe-7s-diamond",
    label: "Vendor Assessments",
    to: "/assessor/vendor/assessments",
    internalRoutes: ["/assessor/vendor/assessment/"],
  },
];
export const QuestionnairesNav = [
  {
    icon: "pe-7s-diamond",
    label: "Questionnaire",
    to: "/assessor/vendor/questionnaire",
  },
];
export const AssessorNav = [
  {
    icon: "pe-7s-display1",
    label: "Assessors",
    to: "/assessor/admin",
  },
];
export const BusinessNav = [
  {
    icon: "pe-7s-diamond",
    label: "Business Organizations",
    to: "/assessor/business-org",
  },
];

export const BusinessDomainsNav = [
  {
    icon: "pe-7s-diamond",
    label: "Business Domains",
    to: "/assessor/business-domains",
  },
];

export const RiderMatrixNav = [
  {
    icon: "pe-7s-file",
    label: "Rider Details",
    to: "/assessor/rider-details",
  },
];

export const VendorQuestionnaireNav = [
  {
    icon: "pe-7s-rocket",
    label: "Vendor Questionnaire",
    to: "/assessor/vendor-questionnaire",
  },
];

export const RemediationsNav = [
  {
    icon: "pe-7s-tools",
    label: "Remediations",
    to: "/assessor/remediations",
    internalRoutes: ["/remediation/"],
  },
];

export const ClientSupportNav = [
  {
    icon: "pe-7s-headphones",
    label: "Client Support",
    to: "/assessor/client-support",
  },
];

export const RolesAndPermissionDetails = [
  {
    icon: "pe-7s-id",
    label: "Roles and Permissions",
    to: "/assessor/roles-permissions",
  },
];

export const TPRMSupportServicesNav = [
  {
    icon: "pe-7s-mail-open",
    label: "TPRM Support Services",
    to: "/assessor/tprm-support-service-requests",
    internalRoutes: [""],
  },
];
