import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getUsersByRole } from "../../../services/userService";
import { Roles } from "../../../utils/constants";

const AssessorPopup = ({
  isAssessorPopupOpen,
  setIsAssessorPopupOpen,
  handleAssessorAssignment,
}) => {
  const colDefs = [
    {
      headerName: "Name",
      renderCell: (params) => {
        return `${params.row.firstName} ${params.row.lastName}`;
      },
      flex: 1,
    },
  ];

  // const [userRowSelection, setUserRowSelection] = useState([]);

  const getUsersList = async () => {
    const data = await getUsersByRole([
      Roles.ASSESSOR,
      Roles.LEADASSESSOR.replace(" ", ""),
    ]);
    setAssessorList(data);
  };

  const [assessorList, setAssessorList] = useState([]);

  useEffect(() => {
    if (isAssessorPopupOpen) getUsersList();
  }, [isAssessorPopupOpen]);

  const handleSave = (userRowSelection) => {
    if (userRowSelection.length === 0) {
      toast("Please Select Assessor", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    handleAssessorAssignment(
      assessorList.filter((x) => x.id === userRowSelection[0])[0]
    );
    setIsAssessorPopupOpen(false);
  };

  return (
    <>
      <Modal
        size="sm"
        isOpen={isAssessorPopupOpen}
        toggle={() => setIsAssessorPopupOpen(false)}
      >
        <ModalHeader toggle={() => setIsAssessorPopupOpen(false)}>
          {"Assign To"}
        </ModalHeader>
        <ModalBody>
          <div className="assessor-popup-list-grid-container">
            <DataGrid
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              rows={assessorList}
              columns={colDefs}
              hideFooter
              onRowSelectionModelChange={(newRowSelectionModel) => {
                handleSave(newRowSelectionModel);
              }}
              density="compact"
            />
          </div>
        </ModalBody>
        {/* <ModalFooter>
                    <Button color="primary" onClick={() => handleSave()}>Save</Button>
                    <Button onClick={() => setIsAssessorPopupOpen(false)}>Cancel</Button>
                </ModalFooter> */}
      </Modal>
    </>
  );
};

export default AssessorPopup;
