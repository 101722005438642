"use client";

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
// import { useNavigate } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function AssessorList({ sx }) {
  // let navigate = useNavigate();

  const assessments = [
    {
      assignee: "Nagaraj K",
      workPercentage: 61,
      count: 36,
    },
    {
      assignee: "Himanshu J",
      workPercentage: 15,
      count: 9,
    },
    {
      assignee: "Haripal S",
      workPercentage: 12,
      count: 7,
    },
    {
      assignee: "Sandeep S",
      workPercentage: 10,
      count: 6,
    },
    {
      assignee: "Unassigned",
      workPercentage: 2,
      count: 1,
    },
  ];

  return (
    <Card sx={sx}>
      <CardHeader
        title="Assessors Overview"
        sx={{
          height: 80,
        }}
      />
      <Divider />
      <Box sx={{ overflowX: "auto" }}>
        <TableContainer sx={{ maxHeight: 620 }}>
          <Table sx={{ minWidth: 800 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Assignee</TableCell>
                <TableCell>Work distribution</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assessments.map((order) => {
                const colors = [
                  "primary",
                  "secondary",
                  "error",
                  "info",
                  "success",
                  "warning",
                ];
                const color = colors[Math.floor(Math.random() * colors.length)];

                return (
                  <TableRow hover key={order.assignee}>
                    <TableCell>{order.assignee}</TableCell>
                    <TableCell>
                      <LinearProgressWithLabel
                        value={order.workPercentage}
                        color={color}
                      />
                    </TableCell>
                    <TableCell>{order.count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
}
