import React from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { hasValue } from "../../utils/commonFns";

const ConfirmationPopup = ({ deleteItem, handleConfirmationPopup }) => {
  return (
    <>
      <Modal
        size="md"
        isOpen={hasValue(deleteItem)}
        toggle={() => handleConfirmationPopup(false)}
      >
        <ModalHeader toggle={() => handleConfirmationPopup(false)}>
          {"Confirmation"}
        </ModalHeader>
        <ModalBody>
          <Label className="center-align">
            Are you sure, do you want to delete ?
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              handleConfirmationPopup(true);
            }}
          >
            Continue
          </Button>
          <Button onClick={() => handleConfirmationPopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmationPopup;
