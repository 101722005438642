"use client";

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";

import Chart from "react-apexcharts";

export function AssessmentsGraph({ chartSeries, chartLabels, sx }) {
  const chartOptions = useChartOptions(chartSeries, chartLabels);
  // let navigate = useNavigate();

  return (
    <Card sx={sx}>
      <CardHeader
        title="Assessments Overview"
        sx={{
          height: 80,
        }}
      />
      <Divider />
      <CardContent className="tprm-donut-graph">
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
        />
      </CardContent>
    </Card>
  );
}

function useChartOptions(chartSeries, chartLabels) {
  const theme = useTheme();

  const total = chartSeries.reduce((sum, value) => sum + value, 0);

  let customLegendFormatter = (seriesName, opts) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${
            seriesName === "Total" ? total : chartSeries[opts.seriesIndex]
          }</div>
      `;
  };

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: { show: false },
    },
    colors: ["#9c27b0", "#03a9f4", "#ffc107", "#8bc34a", "#4caf50"],
    dataLabels: { enabled: false },
    fill: { opacity: 1, type: "solid" },

    legend: {
      show: true,
      position: "right",
      formatter: customLegendFormatter,
      customLegendItems: [...chartLabels, "Total"],
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              formatter: (value) => {
                const percentage = ((value / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
            total: {
              show: true,
              label: "Total",
            },
          },
        },
      },
    },
    stroke: { colors: ["transparent"], show: true, width: 2 },
    theme: { mode: theme.palette.mode },
    labels: chartLabels,
  };
}
