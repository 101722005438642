import axios from "axios";
import { toast } from "react-toastify";
import { hasValue } from "./commonFns";

axios.interceptors.request.use(
  function (config) {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    if (!config.url.endsWith("SendEmail")) {
      document.body.classList.add("loading-indicator");
      document.getElementById("loader").classList.add("loader");
    }

    let user = window.localStorage.getItem("currentUser");
    if (user && !config.url.toString().includes("s3.amazonaws.com")) {
      user = JSON.parse(user);
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    if (!config.headers.Accept) config.headers.Accept = "application/json";
    if (!config.headers["Content-Type"])
      config.headers["Content-Type"] = "application/json";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    try {
      document.body.classList.remove("loading-indicator");
      document.getElementById("loader").classList.remove("loader");

      if (!response.data.success && hasValue(response.data.error)) {
        toast(`Error - ${response.data.error}`, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
        });
      }
      return response;
    } catch (error) {
      console.log("error: ".error);
    }
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    document.getElementById("loader")?.classList.remove("loader");
    if (
      error?.response?.data?.error &&
      typeof error?.response?.data?.error === "string"
    ) {
      toast(`${error?.response?.data?.error}`, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const errors = Object.values(error?.response?.data?.errors || {})?.join(
        "\n\n"
      );
      toast(
        `Error - ${error.message}\n${error?.response?.data?.title}\n${errors}`,
        {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
    return Promise.reject(error);
  }
);
