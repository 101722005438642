import React, { Fragment } from 'react';
import AppMobileMenu from '../AppMobileMenu';
import logo from "../../assets/utils/images/beaconer_logo.png";

const HeaderLogo = (props) => {
    return (
        <Fragment>
            <div className="app-header__logo bg-royal">
                <div className="logo-src">
                    <img alt='Header Logo' className="logo-img" src={logo} />
                </div>
            </div>
            <AppMobileMenu />
        </Fragment>
    )

}



export default HeaderLogo;