import axios from "axios";
import AppConfig from "../config";

const baseURL = `${AppConfig.API_URL}/Remediation`;

const getRemediations = async () => {
  try {
    let { data } = await axios.get(`${baseURL}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getGapsByRemediationID = async (remediationId) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-gaps-by-remediation/${remediationId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
const getRemediation = async (remediationId) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-remediation/${remediationId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const linkAssessor = async (request) => {
  try {
    let { data } = await axios.put(`${baseURL}/link-assessor`, request);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const updateRemediationStatus = async (request) => {
  try {
    let { data } = await axios.put(
      `${baseURL}/update-remediation-status`,
      request
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const resetRemediationStatus = async (request) => {
  try {
    let { data } = await axios.put(
      `${baseURL}/reset-remediation-status`,
      request
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const updateRemediationGapStatus = async (request) => {
  try {
    let { data } = await axios.put(
      `${baseURL}/update-remediation-gap-status`,
      request
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const uploadEvidence = async (request) => {
  try {
    let { data } = await axios.post(`${baseURL}/update-evidence`, request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getEvidence = async (remediationGapID) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-evidence/${remediationGapID}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const postNewQuery = async (request) => {
  try {
    let { data } = await axios.post(`${baseURL}/post-query`, request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getQuery = async (remediationGapID) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-query?remediationGapID=${remediationGapID}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export {
  getRemediations,
  getGapsByRemediationID,
  getRemediation,
  linkAssessor,
  updateRemediationStatus,
  updateRemediationGapStatus,
  uploadEvidence,
  getEvidence,
  resetRemediationStatus,
  postNewQuery,
  getQuery,
};
