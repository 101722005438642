import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function ConfirmationModal({
  isNewConfirmationModalOpen,
  setConfirmationModalOpen,
  bodyText,
}) {
  return (
    <>
      <Modal
        size="md"
        isOpen={isNewConfirmationModalOpen}
        toggle={() => setConfirmationModalOpen(false)}
      >
        <ModalHeader toggle={() => setConfirmationModalOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => setConfirmationModalOpen(false, true)}
          >
            Yes
          </Button>
          <Button onClick={() => setConfirmationModalOpen(false)}>No</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
