import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getRiderById,
  addRider,
  updateRider,
} from "../../services/riderMatrixService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { hasValue } from "../../utils/commonFns";

const AddUpdateRiderMatrix = ({
  isNewRiderMatrixModalOpen,
  setIsNewRiderMatrixModalOpen,
  riderMatrixId,
}) => {
  const [riderDetails, setRiderDetails] = useState({
    name: "",
    docName: "",
    docSignedUrl: "",
    fileslist: null,
  });

  const onFieldChanges = (e) => {
    setRiderDetails({
      ...riderDetails,
      name: e.target.value,
    });
  };

  const getRiderInfoById = async () => {
    const result = await getRiderById(riderMatrixId);
    setRiderDetails({
      name: result.name,
      docName: result.docName,
      docSignedUrl: result.docSignedUrl,
      fileslist: null,
    });
  };

  useEffect(() => {
    if (riderMatrixId > 0) getRiderInfoById(riderMatrixId);
  }, []);

  const saveRider = async () => {
    if (!hasValue(riderDetails.name)) {
      toast("Name is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!hasValue(riderDetails.fileslist)) {
      toast("Document is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const rider = new FormData();

    rider.append("name", riderDetails.name);

    if (hasValue(riderDetails.fileslist))
      rider.append("RiderDocument", riderDetails.fileslist);

    let result,
      sucessMessage = "Rider saved Successfully.";
    if (riderMatrixId > 0) {
      sucessMessage = "Rider Updated Successfully.";
      result = await updateRider(rider);
    } else {
      result = await addRider(rider);
    }

    if (hasValue(result) && result) {
      toast(sucessMessage, {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsNewRiderMatrixModalOpen(false, true);
    }
  };

  const handleModalClose = () => {
    setIsNewRiderMatrixModalOpen(false);
  };

  const uploadRiderDocChange = (e) => {
    if (!hasValue(e) || !hasValue(e.target) || !hasValue(e.target.files))
      return;
    var fileslist = e.target.files;
    if (fileslist.length <= 0) return;
    const file = fileslist[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      toast("File Size cannot be greater than 5Mb", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    for (var index = 0; index < fileslist.length; index++) {
      setRiderDetails({
        ...riderDetails,
        docName: file.name,
        docSignedUrl: "",
        fileslist: fileslist[index],
      });
    }
    e.target.value = "";
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={isNewRiderMatrixModalOpen}
        toggle={handleModalClose}
      >
        <ModalHeader toggle={handleModalClose}>
          {riderMatrixId > 0 ? "Update Rider" : "Add Rider"}
        </ModalHeader>
        <ModalBody>
          <Card className="main-card">
            <CardBody>
              <div className="business-add-card">
                <Form inline>
                  <Row>
                    <Col md={6}>
                      <Label
                        required
                        for="riderName"
                        className="left-align required col-form-label"
                      >
                        Name
                      </Label>
                      <Col>
                        <Input
                          name="riderName"
                          id="riderName"
                          value={riderDetails.name}
                          placeholder="Enter Rider Name"
                          onChange={(e) => {
                            onFieldChanges(e);
                          }}
                        />
                      </Col>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <Label
                        required
                        for="uploadRiderDoc"
                        className="left-align required col-form-label"
                      >
                        Document
                      </Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="RiderDoc"
                          autocomplete="off"
                          value={riderDetails.docName}
                          placeholder="Please Select Rider Document"
                          readOnly
                        />
                        <InputGroupText>
                          <input
                            name="uploadRiderDoc"
                            id="uploadRiderDoc"
                            style={{ display: "none" }}
                            type="file"
                            accept=".doc,.docx, application/msword, text/plain, application/pdf, image/*"
                            onChange={uploadRiderDocChange}
                          />
                          <Label for="uploadRiderDoc">
                            <FontAwesomeIcon title="fileSelect" icon={faFile} />
                          </Label>
                        </InputGroupText>
                      </InputGroup>
                      {riderDetails.docSignedUrl && (
                        <>
                          <Col md={6}>
                            {" "}
                            <a href={riderDetails.docSignedUrl} target="_">
                              {riderDetails.docName}
                            </a>
                          </Col>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveRider}>
            {riderMatrixId > 0 ? "Update" : "Save"}
          </Button>
          <Button onClick={handleModalClose}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddUpdateRiderMatrix;
