import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { hasValue } from "../../utils/commonFns";
import { toast } from "react-toastify";
import { createUpdateSection } from "../../services/vendorQuestionnaire";

const EditSectionPopup = ({
  editSection,
  setEditSection,
  loadQuestionnaireData,
}) => {
  const [sectionName, setSectionName] = useState("");

  useEffect(() => {
    setSectionName(editSection?.sectionName || "");
  }, [editSection]);

  const handleUpdate = async () => {
    if (!hasValue(sectionName)) {
      toast("Please Enter Section Name", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const response = await createUpdateSection({
      sectionId: editSection.id,
      sectionName,
    });

    if (response) {
      toast(
        editSection?.id > 0
          ? "Section Updated Successfully"
          : "Section Saved Successfully",
        {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setEditSection(null);
      loadQuestionnaireData();
    }
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={hasValue(editSection)}
        toggle={() => setEditSection(null)}
        className="editquestionpopup"
      >
        <ModalHeader toggle={() => setEditSection(null)}>
          {editSection?.id > 0 ? "Edit Section" : "Add Section"}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Form inline>
                <Row>
                  <Col>
                    <Label className="left-align required col-form-label">
                      Section Name
                    </Label>
                    <Input
                      type="text"
                      value={sectionName}
                      onChange={(evt) => setSectionName(evt.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdate}>
            {editSection?.id > 0 ? "Update" : "Save"}
          </Button>
          <Button color="secondary" onClick={() => setEditSection(null)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditSectionPopup;
