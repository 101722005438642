import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { word } from "../../utils/commonFns";
import {
  getPublicVendorById,
  addPublicVendors,
  UpdatePublicVendors,
} from "../../services/PublicvendorService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { hasValue } from "../../utils/commonFns";

const AddUpdatePublicVendors = ({
  isNewPublicVendorsModalOpen,
  setIsNewPublicVendorsModalOpen,
  PublicVendorsId,
}) => {
  const [logoInfo, setLogoInfo] = useState({
    logoName: "",
    logoUrl: "",
    fileslist: null,
  });

  const [fields, setFields] = useState([
    {
      field: "name",
      header: "Name",
      value: "",
      category: "publicVendors",
    },
    {
      field: "universalName",
      header: "Universal Name",
      value: "",
      category: "publicVendors",
    },
    {
      field: "website",
      header: "Website",
      value: "",
      category: "publicVendors",
    },
    {
      field: "linkedinUrl",
      header: "Linked-In Url",
      value: "",
      category: "publicVendors",
    },
    {
      field: "description",
      header: "Description",
      value: "",
      category: "publicVendorsDesc",
    },
    {
      field: "logoName",
      header: "Logo",
      value: "",
      category: "publicVendors",
    },
  ]);

  const getPublicVendorInfoById = async () => {
    const result = await getPublicVendorById(PublicVendorsId);
    setLogoInfo({
      logoName: result.logoName,
      logoUrl: result.logoUrl,
    });

    fields.forEach((f) => {
      f.value = result[f.field];
    });
    setFields(JSON.parse(JSON.stringify(fields)));
  };

  useEffect(() => {
    if (PublicVendorsId > 0) getPublicVendorInfoById(PublicVendorsId);
  }, []);

  const savepublicVendor = async () => {
    const publicVendor = new FormData();

    fields.forEach((f) => {
      publicVendor.append(f.field, f.value);
    });

    if (!hasValue(publicVendor.get("name"))) {
      toast("Name is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!hasValue(publicVendor.get("website"))) {
      toast("Website is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!hasValue(publicVendor.get("linkedinUrl"))) {
      toast("Linked-In Url is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!hasValue(publicVendor.get("logoName"))) {
      toast("Logo is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!hasValue(publicVendor.get("description"))) {
      toast("Description is required", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (hasValue(logoInfo.logoUrl))
      publicVendor.append("logoUrl", logoInfo.logoUrl);

    if (hasValue(logoInfo.logoKey))
      publicVendor.append("LogoKey", logoInfo.logoKey);

    if (hasValue(logoInfo.fileslist))
      publicVendor.append("LogoFile", logoInfo.fileslist);

    let result,
      sucessMessage = "Vendor saved Successfully.";
    if (PublicVendorsId > 0) {
      publicVendor.append("id", PublicVendorsId);
      sucessMessage = "Vendor Updated Successfully.";
      result = await UpdatePublicVendors(publicVendor);
    } else {
      result = await addPublicVendors(publicVendor);
    }

    if (hasValue(result) && result) {
      toast(sucessMessage, {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      setIsNewPublicVendorsModalOpen(false, true);
    }
  };

  const onFieldChanges = (e, field) => {
    setFields((prev) => {
      return prev.map((f) => {
        if (f.field === field) {
          return {
            ...f,
            value: e.target.value,
          };
        } else return f;
      });
    });
  };

  const handleModalClose = () => {
    setIsNewPublicVendorsModalOpen(false);
  };

  const uploadLogoChange = (e) => {
    if (!hasValue(e) || !hasValue(e.target) || !hasValue(e.target.files))
      return;
    var fileslist = e.target.files;
    if (fileslist.length <= 0) return;
    const file = fileslist[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      toast("File Size cannot be greater than 5Mb", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    for (var index = 0; index < fileslist.length; index++) {
      setLogoInfo({
        logoName: file.name,
        logoUrl: "",
        fileslist: fileslist[index],
      });
    }

    fields.find((item) => item.field === "logoName").value = file.name;
    setFields(JSON.parse(JSON.stringify(fields)));
    e.target.value = "";
  };

  const getInputField = ({ field, value, placeholder, maxLength }) => {
    if (field === "logoName") {
      return (
        <>
          <InputGroup>
            <Input
              type="text"
              name={field}
              autocomplete="off"
              value={value}
              placeholder="Please Select Logo file"
              readOnly
            />
            <InputGroupText>
              <input
                name="uploadLogoFile"
                id="uploadLogoFile"
                style={{ display: "none" }}
                type="file"
                accept=".jpg,.png"
                onClick={uploadLogoChange}
              />
              <Label for="uploadLogoFile">
                <FontAwesomeIcon title="fileSelect" icon={faFile} />
              </Label>
            </InputGroupText>
          </InputGroup>
          <Col>
            {logoInfo.logoUrl && (
              <>
                <Col md={6}>
                  {" "}
                  <a href={logoInfo.logoUrl} target="_">
                    {logoInfo.logoName}
                  </a>
                </Col>
              </>
            )}
          </Col>
        </>
      );
    } else if (field === "description") {
      return (
        <Input
          type="textarea"
          name={field}
          id={field}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={(e) => {
            onFieldChanges(e, field);
          }}
        />
      );
    } else
      return (
        <Input
          name={field}
          id={field}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={(e) => {
            onFieldChanges(e, field);
          }}
        />
      );
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={isNewPublicVendorsModalOpen}
        toggle={handleModalClose}
      >
        <ModalHeader toggle={handleModalClose}>
          {PublicVendorsId > 0 ? "Update Public Vendor" : "Add Public Vendor"}
        </ModalHeader>
        <ModalBody>
          <Card className="main-card">
            <CardBody>
              <div className="public-vendor-add-card">
                <Form inline>
                  <Row>
                    <h5>Public Vendor Details</h5>
                    {fields.map((item) =>
                      item.category === "publicVendorsDesc" ? (
                        <Col md={12}>
                          <Label
                            required
                            for={item.field}
                            className="left-align required col-form-label"
                          >
                            {word(item.header)}
                          </Label>
                          <Col>{getInputField(item)}</Col>
                        </Col>
                      ) : (
                        <Col md={6}>
                          <Label
                            required
                            for={item.field}
                            className="left-align required col-form-label"
                          >
                            {word(item.header)}
                          </Label>
                          <Col>{getInputField(item)}</Col>
                        </Col>
                      )
                    )}
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={savepublicVendor}>
            {PublicVendorsId > 0 ? "Update" : "Save"}
          </Button>
          <Button onClick={handleModalClose}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddUpdatePublicVendors;
