import { ThemeActionTypes } from './actions';

export const initialState = {
	backgroundColor: 'bg-royal sidebar-text-light',
	headerBackgroundColor: 'bg-white header-text-dark',
	enableMobileMenuSmall: '',
	enableBackgroundImage: true,
	enableClosedSidebar: false,
	enableFixedHeader: true,
	enableHeaderShadow: true,
	enableSidebarShadow: true,
	enableFixedFooter: true,
	enableFixedSidebar: true,
	colorScheme: 'white',
	backgroundImage: null,
	backgroundImageOpacity: 'opacity-06',
	enablePageTitleIcon: true,
	enablePageTitleSubheading: true,
	enablePageTabsAlt: false,
};

export const themeReducer = (state, action) => {
	switch (action.type) {
		case ThemeActionTypes.SET_ENABLE_BACKGROUND_IMAGE:
			return {
				...state,
				enableBackgroundImage: action.payload.enableBackgroundImage
			};

		case ThemeActionTypes.SET_ENABLE_FIXED_HEADER:
			return {
				...state,
				enableFixedHeader: action.payload.enableFixedHeader
			};

		case ThemeActionTypes.SET_ENABLE_HEADER_SHADOW:
			return {
				...state,
				enableHeaderShadow: action.payload.enableHeaderShadow
			};

		case ThemeActionTypes.SET_ENABLE_SIDEBAR_SHADOW:
			return {
				...state,
				enableSidebarShadow: action.payload.enableSidebarShadow
			};

		case ThemeActionTypes.SET_ENABLE_PAGETITLE_ICON:
			return {
				...state,
				enablePageTitleIcon: action.payload.enablePageTitleIcon
			};

		case ThemeActionTypes.SET_ENABLE_PAGETITLE_SUBHEADING:
			return {
				...state,
				enablePageTitleSubheading: action.payload.enablePageTitleSubheading
			};

		case ThemeActionTypes.SET_ENABLE_PAGE_TABS_ALT:
			return {
				...state,
				enablePageTabsAlt: action.payload.enablePageTabsAlt
			};

		case ThemeActionTypes.SET_ENABLE_FIXED_SIDEBAR:
			return {
				...state,
				enableFixedSidebar: action.payload.enableFixedSidebar
			};

		case ThemeActionTypes.SET_ENABLE_MOBILE_MENU:
			return {
				...state,
				enableMobileMenu: action.payload.enableMobileMenu
			};

		case ThemeActionTypes.SET_ENABLE_MOBILE_MENU_SMALL:
			return {
				...state,
				enableMobileMenuSmall: action.payload.enableMobileMenuSmall
			};

		case ThemeActionTypes.SET_ENABLE_CLOSED_SIDEBAR:
			return {
				...state,
				enableClosedSidebar: action.payload.enableClosedSidebar
			};

		case ThemeActionTypes.SET_ENABLE_FIXED_FOOTER:
			return {
				...state,
				enableFixedFooter: action.payload.enableFixedFooter
			};

		case ThemeActionTypes.SET_BACKGROUND_COLOR:
			return {
				...state,
				backgroundColor: action.payload.backgroundColor
			};

		case ThemeActionTypes.SET_HEADER_BACKGROUND_COLOR:
			return {
				...state,
				headerBackgroundColor: action.payload.headerBackgroundColor
			};

		case ThemeActionTypes.SET_COLOR_SCHEME:
			return {
				...state,
				colorScheme: action.payload.colorScheme
			};

		case ThemeActionTypes.SET_BACKGROUND_IMAGE:
			return {
				...state,
				backgroundImage: action.payload.backgroundImage
			};

		case ThemeActionTypes.SET_BACKGROUND_IMAGE_OPACITY:
			return {
				...state,
				backgroundImageOpacity: action.payload.backgroundImageOpacity
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};