import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { hasValue } from "../../utils/commonFns";
import { toast } from "react-toastify";
import { createUpdateQuestion } from "../../services/vendorQuestionnaire";

const EditQuestionPopup = ({
  editQuestion,
  setEditQuestion,
  selectedSection,
  loadQuestionnaireData,
}) => {
  const [idealResponse, setIdealResponse] = useState("");
  const [questionName, setQuestionName] = useState("");

  useEffect(() => {
    setIdealResponse(editQuestion?.idealResponse || "");
    setQuestionName(editQuestion?.question_name || "");
  }, [editQuestion]);

  const handleUpdate = async () => {
    if (!hasValue(questionName)) {
      toast("Please Enter Question", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    // if (!hasValue(idealResponse)) {
    //   toast("Please Select Ideal Response", {
    //     type: "error",
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }

    const response = await createUpdateQuestion({
      sectionId: selectedSection.id,
      questionId: editQuestion?.id,
      questionName,
      idealResponse,
    });

    if (response) {
      toast(
        editQuestion?.id > 0
          ? "Question Updated Successfully"
          : "Question Saved Successfully",
        {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setEditQuestion(null);
      loadQuestionnaireData();
    }
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={hasValue(editQuestion)}
        toggle={() => setEditQuestion(null)}
        className="editquestionpopup"
      >
        <ModalHeader toggle={() => setEditQuestion(null)}>
          {editQuestion?.id > 0 ? "Edit Question" : "Add Question"}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Form inline>
                <Row>
                  <Col>
                    <Label className="left-align required col-form-label">
                      Section Name
                    </Label>
                    <Label>{selectedSection.sectionName}</Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="left-align required col-form-label">
                      Question Name
                    </Label>
                    <Input
                      type="text"
                      value={questionName}
                      onChange={(evt) => setQuestionName(evt.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="left-align required col-form-label">
                      Ideal Response
                    </Label>
                    <Input
                      type="select"
                      value={idealResponse}
                      onChange={(evt) => setIdealResponse(evt.target.value)}
                    >
                      <option value={""}>-- Select --</option>
                      <option value={"Y"}>Yes</option>
                      <option value={"N"}>No</option>
                      <option value={"NA"}>NA</option>
                    </Input>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdate}>
            {editQuestion?.id > 0 ? "Update" : "Save"}
          </Button>
          <Button color="secondary" onClick={() => setEditQuestion(null)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditQuestionPopup;
