import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import AppContent from "../AppContent";
import { DataGrid } from "@mui/x-data-grid";
import { Card, CardBody, Breadcrumb, BreadcrumbItem } from "reactstrap";
export default function RolesAndPermissionDetails() {
  const colDefs = [
    {
      field: "beconerPlatform",
      headerName: "Beconer Platform",
      width: 250,
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
    },
    {
      field: "entitlement",
      headerName: "Entitlement",
      width: 600,
      renderCell: (params) => {
        return (
          <div class="MuiDataGrid-cellContent">
            {params.row.entitlement.map((item) => {
              return <p> &#x2022; {item}</p>;
            })}
          </div>
        );
      },
    },
  ];

  const rolesInfo = [
    {
      id: 1,
      beconerPlatform: "Assessor",
      role: "Super Admin",
      entitlement: [
        "Super admin account can be accessed through the unique credentials",
        "No user can be granted access as Super Admin",
        "Can manage Assessor user account",
        "Can manage Business Client account",
        "Can manage metadata of entire platform as well",
      ],
    },
    {
      id: 2,
      beconerPlatform: "Assessor",
      role: "Lead Assessor",
      entitlement: [
        "Can draft and approve assessment reports",
        "Can assign assessments to Assessor",
        "Can perform all assessor actions",
      ],
    },
    {
      id: 3,
      beconerPlatform: "Assessor",
      role: "Assessor",
      entitlement: [
        "Can draft assessment reports",
        "Can submit the assessment report to Lead assessor",
        "Can create new vendors",
        "Can send questionnaire to vendors",
      ],
    },
    {
      id: 4,
      beconerPlatform: "Client",
      role: "Admin",
      entitlement: [
        "Can access the complete client dashboard",
        "Can submit a new assessment request",
        "Can accept an assessment report",
        "Can send the report back to beaconer for queries",
        "Can manage team within organisation",
      ],
    },
    {
      id: 5,
      beconerPlatform: "Client",
      role: "User",
      entitlement: [
        "Can submit a new assessment request",
        "Can veiw the status of their submitted assessment",
      ],
    },
    {
      id: 6,
      beconerPlatform: "Vendor",
      role: "User",
      entitlement: [
        "Can view & respond the assessment assigned to them",
        "Can manage team within organisation",
        "Can assign the assessment to other team members",
      ],
    },
  ];

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle
                heading="Roles & Permissions"
                BreadcrumbComp={
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Roles & Permissions</BreadcrumbItem>
                  </Breadcrumb>
                }
              />
              <Card className="main-card mb-6">
                <CardBody>
                  <div className="roles-permission-grid-container">
                    <DataGrid
                      pageSizeOptions={[5, 10, 25]}
                      rows={rolesInfo}
                      columns={colDefs}
                      disableSelectionOnClick
                      disableRowSelectionOnClick
                      density="compact"
                      sx={{
                        "& .MuiDataGrid-cellContent": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          padding: "8px",
                        },
                      }}
                      getRowHeight={() => "auto"}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
