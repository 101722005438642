import React from 'react';
// import { useThemeState } from '../../context/theme';
// import TitleComponent2 from './PageTitleExamples/Variation2'

const PageTitle = (props) => {

    // let {
    //     enablePageTitleIcon,
    //     enablePageTitleSubheading,
    // } = useThemeState();

    let {
        heading,
        // icon,
        // subheading,
        PageTitleActions,
        BreadcrumbComp
    } = props;

    return (

        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div>

                    {BreadcrumbComp != null && (
                        <div className='page-title-breadcrumbs'>
                            {BreadcrumbComp}
                        </div>
                    )}
                    <div className="page-title-heading">
                        {heading}
                    </div>
                </div>
                {
                    PageTitleActions != null && (
                        <div className="page-title-actions">
                            {PageTitleActions}
                        </div>
                    )
                }
            </div>
        </div>
    );

}

export default PageTitle;