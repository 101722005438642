import React, { useState, Fragment } from 'react';

import { Slider } from "react-burgers";

import cx from 'classnames';

import {
    faEllipsisV,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    Button
} from 'reactstrap';

// import {
//     setEnableMobileMenu,
//     setEnableMobileMenuSmall,
// } from '../../reducers/ThemeOptions';

import { ThemeActions, useThemeState, useThemeDispatch } from '../../context/theme';

const AppMobileMenu = (props) => {


    const [active, setActive] = useState(false);
    // const [mobile, setMobile] = useState(false);
    const [activeSecondaryMenuMobile] = useState(false); // setActiveSecondaryMenuMobile

    let {
        enableMobileMenu,
        enableMobileMenuSmall,
    } = useThemeState();

    const dispatch = useThemeDispatch();

    const toggleMobileSidebar = () => {
        ThemeActions.SET_ENABLE_MOBILE_MENU(dispatch, { enableMobileMenu: !enableMobileMenu });
    }


    const toggleMobileSmall = () => {
        ThemeActions.SET_ENABLE_MOBILE_MENU_SMALL(dispatch, { enableMobileMenuSmall: !enableMobileMenuSmall });
    }

    return (
        <Fragment>

            <div className="app-header__mobile-menu">
                <div onClick={toggleMobileSidebar}>
                    <Slider
                        color="#fff"
                        active={enableMobileMenu}
                        type="elastic"
                        onClick={() => setActive(!active)}
                    />
                </div>
            </div>
            <div className="app-header__menu">
                <span onClick={toggleMobileSmall}>
                    <Button size="sm"
                        className={cx("btn-icon btn-icon-only", { active: activeSecondaryMenuMobile })}
                        color="primary"
                        onClick={() => this.setState({ activeSecondaryMenuMobile: !activeSecondaryMenuMobile })}>
                        <div className="btn-icon-wrapper"><FontAwesomeIcon icon={faEllipsisV} /></div>
                    </Button>
                </span>
            </div>
        </Fragment>
    )
}

export default AppMobileMenu;