import { toast } from "react-toastify";

export const hasValue = (val) => {
  return val !== null && val !== undefined && val.toString().trim().length > 0;
};

export const ResponseType = {
  Yes: "Y",
  No: "N",
  NA: "NA",
};
export const SubmissionStatus = {
  SubmittedToVendor: "Submitted To Vendor",
  ResponseReceived: "Response Received",
  Accepted: "Accepted",
};

export const ClientSumissionStatus = {
  Draft: "Draft",
  Requested: "Requested",
  Review: "Review",
  SubmittedToVendor: "Submitted To Vendor",
  InProgress: "In Progress",
  Completed: "Completed",
  Accepted: "Accepted",
  Rejected: "Rejected",
  Cancelled: "Cancelled",
};

export const ApprovalStatus = {
  SubmittedToLead: "SubmittedToLead",
  SubmittedToClient: "SubmittedToClient",
  ClientApproved: "ClientApproved",
  LeadRejected: "LeadRejected",
  ClientRejected: "ClientRejected",
};

export const ApprovalDisplayStatus = {
  SubmittedToLead: "Lead Assessor Pending",
  SubmittedToClient: "Lead Assessor Approved",
  ClientApproved: "Client Approved",
  LeadRejected: "Lead Assessor Rejected",
  ClientRejected: "Client Rejected",
};

export const debounce = (func, timeout = 300) => {
  var timer;
  // return (...args) => {
  //   console.log("timerId is: ", timer);
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     func.apply(this, args);
  //   }, timeout);
  // };

  return function () {
    console.log("timerId is: ", timer);
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, timeout);
  };
};

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

export const checkPasswordValidations = (newPassword, confirmPassword) => {
  if (!hasValue(newPassword)) {
    toast("Please Enter New Password", {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  if (!hasValue(confirmPassword)) {
    toast("Please Enter Confirm Password", {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  if (newPassword !== confirmPassword) {
    toast("New Password and Confirm Password does not match", {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  if (!passwordRegex.test(newPassword)) {
    let ToastContent = () => (
      <>
        <b>New Password should meet following criteria.</b>
        <p>1. Should contain Min. 6 - Max. 16 characters</p>
        <p>2. Should contain atleast 1 number</p>
        <p>
          3. Should contain atleast 1 special character {" ! @ # $ % ^ & *"}
        </p>
      </>
    );
    toast(<ToastContent />, {
      autoClose: false,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  return true;
};

export const word = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const assessmentFrameWorkDescription = `Beaconer has successfully conducted a comprehensive third-party risk assessment of {vendor} with a specific focus on evaluating the overall maturity of their enterprise security program and the presence of controls & sub-controls.
\nOur assessment covered a wide spectrum, encompassing all 21 domains, which includes vital areas such as Risk Management, Information Security Policies, Organization of Information Security, Human Resource Security, Asset Management, Access Control, Physical and Environmental Security, Operations Security, Communications Security, System Acquisition, Development, and Maintenance, Third-Party Risk, Information Security Incident Management, Business Continuity Management, Compliance, Privacy, Cloud Security, Container Security & Architecture.
\nTo tailor the identification of Risk & control gaps to this project's scope, we utilized a targeted Questionnaire, focusing on two key dimensions: Control Family maturity & Control completeness.The maturity model assessed the overall maturity of technical, administrative, and people controls across the 21 different control families. Additionally, the control completeness aspect verified the existence of each control & sub-control.
\nAs a result, this comprehensive assessment provides a detailed account of the organizational Security maturity, along with mapping to various Risk frameworks. These findings empower you to measure the effectiveness of the third-party for your specific scope of work, ensuring that your valuable data remains well-protected.
\nYou can find the Beaconer Assessment methodology at  [beaconer.io/resources](http://beaconer.io/resources/)`;

export const ratingSummaryDescription = `Within this section, we present a condensed overview of 10 carefully selected domains out of the comprehensive 21, specifically tailored to the relevance of your project.

By perusing this summary, you gain valuable insights into the organization's overall security readiness, providing you with confidence in safeguarding your project's integrity and data protection.`;

export const ZammadTicketStates = {
  New: "New",
  InProgress: "In progress",
  PendingWithBusiness: "Pending with Business",
  Completed: "Completed",
  Reopened: "Reopened",
  Approved: "Approved",
};

export const ZammadTicketStatesMap = {
  New: { label: "New", color: "success" },
  "In progress": { label: "In progress", color: "warning" },
  "Pending with Business": { label: "In progress", color: "warning" },
  Completed: { label: "Completed", color: "success" },
  Reopened: { label: "In progress", color: "warning" },
  Approved: { label: "In progress", color: "warning" },
};

export const flattenObject = (obj, prefix = "") => {
  let items = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const prefixedKey = prefix ? `${prefix}[${key}]` : key;

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(items, flattenObject(value, prefixedKey));
      } else {
        items[prefixedKey] = value;
      }
    }
  }

  return items;
};

export const jsonToFormData = (json) => {
  const formData = new FormData();
  const flatObject = flattenObject(json);

  for (const key in flatObject) {
    if (flatObject.hasOwnProperty(key) && !!flatObject[key]) {
      formData.append(key, flatObject[key]);
    }
  }

  return formData;
};
