export const ThemeActionTypes = {
    SET_ENABLE_BACKGROUND_IMAGE: 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE',
    SET_ENABLE_MOBILE_MENU: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU',
    SET_ENABLE_MOBILE_MENU_SMALL: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL',
    SET_ENABLE_FIXED_HEADER: 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER',
    SET_ENABLE_HEADER_SHADOW: 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW',
    SET_ENABLE_SIDEBAR_SHADOW: 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW',
    SET_ENABLE_FIXED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR',
    SET_ENABLE_CLOSED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR',
    SET_ENABLE_FIXED_FOOTER: 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER',
    SET_ENABLE_PAGETITLE_ICON: 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON',
    SET_ENABLE_PAGETITLE_SUBHEADING: 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING',
    SET_ENABLE_PAGE_TABS_ALT: 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT',
    SET_BACKGROUND_IMAGE: 'THEME_OPTIONS/SET_BACKGROUND_IMAGE',
    SET_BACKGROUND_COLOR: 'THEME_OPTIONS/SET_BACKGROUND_COLOR',
    SET_COLOR_SCHEME: 'THEME_OPTIONS/SET_COLOR_SCHEME',
    SET_BACKGROUND_IMAGE_OPACITY: 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY',
    SET_HEADER_BACKGROUND_COLOR: 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR',
};

const ThemeActions = {
    SET_ENABLE_BACKGROUND_IMAGE: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_BACKGROUND_IMAGE, payload }),
    SET_ENABLE_MOBILE_MENU: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_MOBILE_MENU, payload }),
    SET_ENABLE_MOBILE_MENU_SMALL: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_MOBILE_MENU_SMALL, payload }),
    SET_ENABLE_FIXED_HEADER: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_FIXED_HEADER, payload }),
    SET_ENABLE_HEADER_SHADOW: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_HEADER_SHADOW, payload }),
    SET_ENABLE_SIDEBAR_SHADOW: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_SIDEBAR_SHADOW, payload }),
    SET_ENABLE_FIXED_SIDEBAR: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_FIXED_SIDEBAR, payload }),
    SET_ENABLE_CLOSED_SIDEBAR: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_CLOSED_SIDEBAR, payload }),
    SET_ENABLE_FIXED_FOOTER: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_FIXED_FOOTER, payload }),
    SET_ENABLE_PAGETITLE_ICON: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_PAGETITLE_ICON, payload }),
    SET_ENABLE_PAGETITLE_SUBHEADING: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_PAGETITLE_SUBHEADING, payload }),
    SET_ENABLE_PAGE_TABS_ALT: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_ENABLE_PAGE_TABS_ALT, payload }),
    SET_BACKGROUND_IMAGE: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_BACKGROUND_IMAGE, payload }),
    SET_BACKGROUND_COLOR: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_BACKGROUND_COLOR, payload }),
    SET_COLOR_SCHEME: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_COLOR_SCHEME, payload }),
    SET_BACKGROUND_IMAGE_OPACITY: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_BACKGROUND_IMAGE_OPACITY, payload }),
    SET_HEADER_BACKGROUND_COLOR: (dispatch, payload) => dispatch({ type: ThemeActionTypes.SET_HEADER_BACKGROUND_COLOR, payload }),

}

export default ThemeActions