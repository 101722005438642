import axios from "axios";
import AppConfig from "../config";
import { hasValue } from "../utils/commonFns";

const baseURL = `${AppConfig.API_URL}/TPRMSupport`;

const getRecentAssessments = async (vendorId, allRecords, ticketState) => {
  try {
    let url = `${baseURL}/get-recent-assessments?VendorId=${vendorId}&allRecords=${allRecords}`;

    if (hasValue(ticketState)) {
      url += `&ticketState=${ticketState}`;
    }

    let { data } = await axios.get(url);

    return data;
  } catch (error) {
    console.log(error);
  }
};

const getMontlyAssessments = async (vendorId, ticketState) => {
  try {
    let url = `${baseURL}/get-monthly-assessments?VendorId=${vendorId}`;

    if (hasValue(ticketState)) {
      url += `&ticketState=${ticketState}`;
    }

    let { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getWidgetDataCounts = async (vendorId) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-widget-datacounts?VendorId=${vendorId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getAsssessmentData = async (ticketId) => {
  try {
    let { data } = await axios.get(
      `${baseURL}/get-assessment-data?TicketId=${ticketId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export {
  getRecentAssessments,
  getMontlyAssessments,
  getWidgetDataCounts,
  getAsssessmentData,
};
