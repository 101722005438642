"use client";

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Chart from "react-apexcharts";
import { hasValue } from "../../../utils/commonFns";

export function VendorAssessmentsGraph({ chartSeries, chartLabels, sx }) {
  const chartOptions = useChartOptions(chartSeries, chartLabels);
  // let navigate = useNavigate();

  const [selectedVendor, setSelectedVendor] = React.useState("");
  const handleChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  return (
    <Card sx={sx}>
      <CardHeader
        title="Vendor Assessments Overview"
        action={
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Vendor</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedVendor}
              label="Vendor"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Artemis</MenuItem>
              <MenuItem value={20}>Bluedart</MenuItem>
              <MenuItem value={30}>Xyz Corp.</MenuItem>
              <MenuItem value={30}>Amazon</MenuItem>
              <MenuItem value={30}>Nestle</MenuItem>
            </Select>
          </FormControl>
        }
      />
      <Divider />
      <CardContent className="tprm-donut-graph">
        {hasValue(selectedVendor) ? (
          <Chart
            height={350}
            options={chartOptions}
            series={chartSeries}
            type="donut"
            width="100%"
          />
        ) : (
          <h5>{"Please select a Vendor"}</h5>
        )}
      </CardContent>
    </Card>
  );
}

function useChartOptions(chartSeries, chartLabels) {
  const theme = useTheme();

  const total = chartSeries.reduce((sum, value) => sum + value, 0);

  let customLegendFormatter = (seriesName, opts) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${
            seriesName === "Total" ? total : chartSeries[opts.seriesIndex]
          }</div>
      `;
  };

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: { show: false },
    },
    colors: ["#9c27b0", "#03a9f4", "#ffc107", "#8bc34a", "#4caf50"],
    dataLabels: { enabled: false },
    fill: { opacity: 1, type: "solid" },

    legend: {
      show: true,
      position: "right",
      formatter: customLegendFormatter,
      customLegendItems: [...chartLabels, "Total"],
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              formatter: (value) => {
                const percentage = ((value / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
            total: {
              show: true,
              label: "Total",
            },
          },
        },
      },
    },
    stroke: { colors: ["transparent"], show: true, width: 2 },
    theme: { mode: theme.palette.mode },
    labels: chartLabels,
  };
}
