import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AppContent from "../AppContent";
import Grid from "@mui/material/Unstable_Grid2";
import { Completed } from "./cards/completed";
import { AssessmentsGraph } from "./components/assessments-graph";
import { InProgress7Days } from "./cards/inprogress-7days";
import { InProgress } from "./cards/inprogress";
import { getWidgetDataCounts } from "../../services/tprmSupportService";
import { AllAssessments } from "./cards/all";
import { VendorAssessmentsGraph } from "./components/vendor-assessments-graph";
import { AssessorList } from "./components/assessor-list";
import { AssessorDetailedList } from "./components/assessor-detailed-list";

export default function TPRMSupportServiceDashboard() {
  // const [recentAssessments, setRecentAssessments] = useState([]);
  const [widgetsData, setWidgetsData] = useState({});
  // const [monthlyAssessments, setMonthlyAssessments] = useState([]);

  // const { user } = useAuthState();

  useEffect(() => {
    // const fetchRecentAssessments = async () => {
    //   const { result } = await getRecentAssessments(4, false, null);
    //   setRecentAssessments(result);
    // };

    // const fetchMonthlyAssessments = async () => {
    //   const { result } = await getMontlyAssessments(4, null);
    //   setMonthlyAssessments(result);
    // };

    const fetchWidgetDataCounts = async () => {
      const { result } = await getWidgetDataCounts(4);
      setWidgetsData(result);
    };

    // fetchRecentAssessments();
    // fetchMonthlyAssessments();
    fetchWidgetDataCounts();
  }, []);

  // const calculatePercentagesOfTicketStates = () => {
  //   const statusCounts = [
  //     widgetsData.inProgress,
  //     widgetsData.todo,
  //     widgetsData.completed,
  //     0,
  //   ];

  //   const total = statusCounts.reduce((sum, value) => sum + value, 0);

  //   return statusCounts.map((count) =>
  //     parseInt(((count / total) * 100).toFixed(2))
  //   );
  // };

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <Grid container spacing={3}>
                <Grid lg={3} sm={6} xs={12}>
                  <AllAssessments
                    sx={{ height: "100%" }}
                    diff={0}
                    trend="down"
                    value={widgetsData.todo}
                  />
                </Grid>
                <Grid lg={3} sm={6} xs={12}>
                  <Completed
                    diff={0}
                    trend="up"
                    sx={{ height: "100%", cursor: "pointer" }}
                    value={widgetsData.completed}
                  />
                </Grid>
                <Grid lg={3} sm={6} xs={12}>
                  <InProgress
                    diff={0}
                    trend="down"
                    sx={{ height: "100%" }}
                    value={widgetsData.inProgress}
                  />
                </Grid>
                <Grid lg={3} sm={6} xs={12}>
                  <InProgress7Days
                    sx={{ height: "100%" }}
                    value={widgetsData.dueInNext2Days}
                  />
                </Grid>

                <Grid md={6} xs={12}>
                  <AssessmentsGraph
                    chartSeries={[350, 47, 9, 3, 0]}
                    chartLabels={[
                      "Artemis",
                      "Bluedart",
                      "Xyz Corp.",
                      "Amazon",
                      "Nestle",
                    ]}
                    sx={{ height: "100%" }}
                  />
                </Grid>
                <Grid md={6} xs={12}>
                  <VendorAssessmentsGraph
                    chartSeries={[350, 47, 9, 3, 0]}
                    chartLabels={[
                      "In progress",
                      "To Do",
                      "Completed",
                      "SLA Breaches",
                    ]}
                    sx={{ height: "100%" }}
                  />
                </Grid>
                <Grid md={6} xs={12}>
                  <AssessorList
                    sx={{
                      height: "100%",
                    }}
                  />
                </Grid>
                <Grid md={6} xs={12}>
                  <AssessorDetailedList
                    sx={{
                      height: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
