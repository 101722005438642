import React, { Fragment } from 'react';
import cx from 'classnames';
import AppMain from '../Layout/AppMain';
import { useThemeState } from '../context/theme';

const Main = () => {
    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
    } = useThemeState();

    return (
        <Fragment>
            <div className={cx(
                "app-container app-theme-" + colorScheme,
                { 'fixed-header': enableFixedHeader },
                { 'fixed-sidebar': enableFixedSidebar || document.body.clientWidth < 1250 },
                { 'fixed-footer': enableFixedFooter },
                { 'closed-sidebar': enableClosedSidebar || document.body.clientWidth < 1250 },
                { 'closed-sidebar-mobile': closedSmallerSidebar || document.body.clientWidth < 1250 },
                { 'sidebar-mobile-open': enableMobileMenu },
            )}>
                <AppMain />
            </div>
        </Fragment>
    );
}

export default Main;